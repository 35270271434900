<template>
  <p class="row">Site en construction, rendez-vous <a href="https://www.consonormandie.fr">ici</a></p>
  <NavigationBar/>
  <FooterBar/>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import FooterBar from './components/FooterBar.vue'

export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterBar
  }
}
</script>

<!-- Responsive design -->
<style>
* {
  box-sizing: border-box;

  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
  width: 100%;
}

/*Desktops*/
@media only screen and (min-width: 768px) {
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}
</style>

<!-- Global -->
<style>
  #app {
    color: "black";
  }

  a {
    color: #a115cc;
    text-decoration: none;
  }

  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: bold;
  }

  textarea {
    resize: none;
  }

  em {
    font-style: italic;
  }
</style>