import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import PartnersList from '@/views/PartnersList.vue';
import WriteArticle from '@/views/WriteArticle.vue';
//import axios from "axios";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },

  {
    path: '/Partners',
    name: 'PartnersList',
    component: PartnersList,
  },

  {
    path: '/Articles',
    name: 'WriteArticle',
    component: WriteArticle,
  },

  { //'/:pathMatch(.*)*'
    path: '/:pathMatch(.*)*', redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

/*
async function isLoggedIn (token) {
  try {
      const request = await axios.post('https://www.consonormandie.net/IsLoggedIn.php', null, {params: {token: token}});
      return request.data.response;
  } catch(error) {
      console.log('erreur :', error);
  }
}
*/

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/Partners'];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired && !localStorage.token) {
    return next('/Partners');
  }
  else {
    next();
  }
})

export default router;
