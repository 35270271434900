<template>
    <div class="row">
        
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'HomePage',
        components: {
        },
        //Chaque fois que cette vue est montée, on charge les listes via l'API s'il y a un token Bearer et si la TodoList est vide
        mounted() {
        },
        methods: {
        },
        computed: {
            ...mapGetters('accounts', ['getToken', 'getUserID']),
        }
    }
</script>

<style scoped>
</style>