<template>
    <div class="row" v-if="getToken"> <!-- Formulaire d'inscription -->
        <input class="col-6" v-model="title">
        <textarea class="col-6" v-on:keyup="printCode()" id="article" name="article" rows="5"></textarea>
        <button class="col-3" v-on:click="uploadArticle(title)">Publier l'article</button>
        
        <button class="col-1" v-on:click="insertCode('strong', '')">Gras</button>
        <button class="col-1" v-on:click="insertCode('center', '')">Centrer</button>
        <button class="col-1" v-on:click="insertCode('em', '')">Italique</button>
        <button class="col-1" v-on:click="insertCode('del', '')">Barré</button>
        <button class="col-1" v-on:click="insertCode('h2', '')">En-tête</button>
        <button class="col-1" v-on:click="insertCode('a', 'rel=nofollow href=url')">Hyperlien</button>
    </div>
    <div class="row" v-if="getToken"> <!-- Code HTML interprété -->
        <p class="col-6" id="interpreted"></p>
    </div>

    <form class="row" enctype="multipart/form-data" onsubmit="return false;" >
       <input id="file" type="file" name="static_file" /> <!-- accept="image/*" -->
       <button id="upload-button" v-on:click="uploadFile()"> Upload </button>
    </form>
    <div class="row" id="static_file_response"> </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import axios from 'axios'; 

    export default {
        name: 'WriteArticle',
        methods: {
            uploadArticle: async function (title) {
                try {
                    let field = document.getElementById("article");
                    let content = field.value;

                    const request = await axios.post('https://www.consonormandie.net/PublishArticle.php', null, {params: {token: localStorage.token, title: title, content: content}});
                    console.log(request.data);
                } catch(error) {
                    console.log('erreur :', error);
                }
            },

            insertCode: function (tag, id) {
                let field = document.getElementById("article");
                let val = field.value;
                let selectStart = field.selectionStart; let selectEnd = field.selectionEnd;
                let selected_txt = val.substring(field.selectionStart, field.selectionEnd);
                let before_txt = val.substring(0, field.selectionStart);
                let after_txt = val.substring(field.selectionEnd, val.length);
                selectEnd += id.length + 2 + ('<' + tag + '>').length * 2;
                field.value = before_txt + '<' + tag + " " + id + ">" + selected_txt + '</' + tag + '>' + after_txt;
                field.focus();
                field.setSelectionRange(selectStart, selectEnd);
                this.printCode();
            },
            
            insertBreak: function(fieldTxt, index) {
                let before_txt = fieldTxt.substring(0, index);
                let after_txt = fieldTxt.substring(index, fieldTxt.length);

                return before_txt + '<br/>' + after_txt;
            },

            printCode: function () {
                let field = document.getElementById("article");
                let fieldTxt = field.value;
                
                //Check for break lines
                let regexp = /\n/g||[];
                let matches = [...field.value.matchAll(regexp)];
                let countBreaks = 0; //</br> = 5 
                matches.forEach((match) => {
                    fieldTxt = this.insertBreak(fieldTxt, match.index += countBreaks);
                    countBreaks+=5;
                });

                let fieldHTML = document.getElementById("interpreted");
                fieldHTML.innerHTML = "";
                fieldHTML.insertAdjacentHTML('afterbegin', fieldTxt);
            },

            //to fix
            uploadFile: async function (){
                let formData = new FormData();
                let file = document.getElementById('file').files[0];
                formData.append('file', file, file.name);
                try {
                    const request = await axios.post('https://www.consonormandie.net/Upload.php', file, {params: {token: localStorage.token}},
                    {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }});
                    console.log(request.data);
                } catch(error) {
                    console.log('erreur :', error);
                }
            }
        },

        computed: {
            ...mapGetters('accounts', ['getToken', 'getUserID']),
        }
    }
</script>