<template>
    <div class="row" v-if="!getToken"> <!-- Formulaire d'inscription -->
        <input class="col-6" placeholder="Login" v-model="user" type="text">
        <input class="col-6" placeholder="Password" v-model="password" type="password">
        <button class="col-12" v-on:click="login([user,password])">Se connecter</button>
        <!-- <button class="col-12" v-on:click="register([user,password])">S'enregistrer</button> -->
        
    </div>
    <div class="row" v-else> <!-- Déconnexion -->
        <button class="col-12" v-on:click="disconnect()">Se déconnecter</button>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
    name: 'PartnersList',
    data: function(){
        return {
            user: '',
            password: '',
        }
    },
    methods: {
        ...mapActions('accounts', ['register']),
        ...mapActions('accounts', ['login']),
        ...mapActions('accounts', ['disconnect']),
    },
    computed: {
      ...mapGetters('accounts', ['getToken', 'getUserID']),
    }
    }
</script>