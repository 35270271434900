import axios from "axios";
var SHA512 = require("crypto-js/sha512");

export async function login ({ commit }, [user, password]) {
    try {
        const request = await axios.post('https://www.consonormandie.net/Login.php', null, {params: {user: user, password: SHA512(password)}});
        commit('setToken', request.data.token);
        commit('setUserID', request.data.userid);
    } catch(error) {
        console.log('erreur :', error);
    }
}

export async function register ({ commit }, [user, password]) {
    try {
        const request = await axios.post('https://www.consonormandie.net/Register.php', null, {params: {user: user, password: SHA512(password)}});
        commit('setToken', request.data.token);
    } catch(error) {
        console.log('erreur :', error);
    }
}

export function disconnect ({ commit }) {
    commit('setToken', '');
    commit('setUserID', 0);
}

export function setToken ({ commit }, token) {
    commit('setToken', token);
}